.withdrawProveBox{
    width: 100%;
    display: flex;
    justify-content: center;
}
.withdrawProve {
    width: 620px;
    padding: 10px 40px 20px;
    background-color: #fff;
    background-position: -300px -300px;
    background-repeat: no-repeat;
    background-size: 160px 160px;
}

.withdrawProve p:first-child {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.withdrawProve p:nth-of-type(2) {
    text-align: justify;
    text-indent: 2em;
    margin-bottom: 20px;
}

.withdrawProve p:nth-of-type(3) {
    text-align: justify;
    text-indent: 2em;
    margin-top: 20px;
}

.withdrawProve p:nth-of-type(4) {
    text-indent: 2em;
}

.withdrawProve p:nth-of-type(5) {
    margin-left: 288px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.withdrawProve p:nth-of-type(6) {
    margin-left: 288px;
    margin-top: px;
    margin-bottom: 10px;
}

.withdrawProve p:nth-of-type(7) {
    margin-left: 288px;
    margin-top: 10px;
}

.withdrawProve table {
    width: 100%;
}

.withdrawProve table th {
    width: 50%;
    text-align: center;
    font-weight: 400;
}


