@import '~antd/dist/antd.css';
#root{
  height: 100%;
}
/* .ant-layout {
  height: 100%;
} */
.ml-10{
  margin-left: 10px;
}
.ant-layout-content{
  padding: 0 !important;
}
.is-show{
  display: block;
}
.is-hidden{
  display: none;
}
.ant-form{
  background: #fff !important;
  padding: 10px !important;
}

.ant-table-wrapper{
  margin-top: 20px !important;
}
.tablenotop .ant-table-wrapper{
  margin-top: 0px !important;
}
.ant-page-header-ghost{
  background: #fff !important;
  
}
/* .ant-menu{
  overflow-y: auto !important;
  overflow-x: hidden !important;
} */
.left-span{
  width: 200px;
  text-align: right;
  margin-right: 10px;
  
}
.margin-15{
  margin:15px 0
}
.flex{
  display: flex;
}
.ant-picker{
  width: 100%;
}
/* 登录 */
.login-tab{
  position: absolute;
  width:380px;
  height: 340px;
  margin: 10% 15% 0 0 !important;
  /* background: #ffffff12; */
  padding: 30px !important;
  border: 2px solid #3fb5d245;
}
.login-wrap{
  position: relative;
  background: url('./images/login.jpg') no-repeat center;
  height: 100%;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
}
.copyright{
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 500;
  color: black;
}
.logo-txt{
  color: #fff;
  position: absolute;
  display: flex;
  top: 20px;
  left: 7%;
}
.logo-t1{
  background: url('./images/logo_txt.png') no-repeat center;
  width: 220px;
  height: 50px;
  background-size: contain;
}
.login-form,
.login-form .ant-input-affix-wrapper,
.login-form .ant-input{
  background: transparent !important;
}
.login-wrap .ant-tabs-tab{
  color: #fff;
}
.login-form .ant-input-prefix,
.login-form .ant-input{
  color: #ffffff7a;

}
.login-form-button{
  width: 100%;
  border-radius: 20px !important;
  height: 42px !important;
  margin-top: 30px !important;
  /* background: #011d37; */
  border: none;
}
.login-form-button:hover, .login-form-button:focus{
  /* background: #0f416f; */
  border: none;
}
/* .login-wrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #011d37;
  
} */

/* 首页 */
.home-title{
    /* border-left: 4px solid rgb(68, 161, 250); */
  padding-left: 10px;
  font-size: 21px;
  position: relative;
}
.home-title::before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 4px;
    height: 22px;
    background-color: #08979c;
}
.unit{
  display: inline-block;
}
.home-top{
  height: 20vh;
  width: 32%;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  font-size: 20px;
  color: #fff;
}
.b1{
  background: url('./images/b1.png') no-repeat center;
  background-size: contain;
}
.b2{
  background: url('./images/b2.png') no-repeat center;
  background-size: contain;
}
.b3{
  background: url('./images/b3.png') no-repeat center;
  background-size: contain;
}
.home-top h3{
  font-size: 38px;
  color: #fff;
}
.home-top div {
  padding-left: 140px;
  margin-top: -20px;
}
.home-bottom{
  width: 32%;
  height: 53vh;
  
}
.home-bottom-bg-wrap{
  display: flex;
  flex-wrap: wrap;
}
.home-bottom-bg{
  width: 50%;
  height: 26vh;
  font-size: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.bg1{
  background: url('./images/c1.png') no-repeat center;
  background-size: contain;
}
.bg2{
  background: url('./images/c2.png') no-repeat center;
  background-size: contain;
}
.bg3{
  background: url('./images/c3.png') no-repeat center;
  background-size: contain;
}
.bg4{
  background: url('./images/c4.png') no-repeat center;
  background-size: contain;
}
.home-chart{
  background: #fff;
  padding: 10px;
  height: 100%;
}
.home-chart h3{
  padding: 15px;
}
.myLayout{
  min-height: 100% !important;
}
.tianJinDataTabs .ant-tabs-tab-btn{
  color: #333;
  padding-left:16px;
  padding-right:16px;
  /* min-height: 100vh !important;
  max-height: 100vh; */
}
.wrapl label{
  width: 100%;
  white-space: pre-wrap;
}