.header{
    display: flex;
    justify-content: space-between;
}
.ant-layout {
    height: auto;
    min-height: 100%;
}
.logo{
    background: url('../../images/logo1.png') no-repeat center 2px;
    height: 60px;
    background-size: contain;
}
.ant-layout-header{
    background: #fff !important;
}
.my-layout-Sider{
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.my-layout-Sider::-webkit-scrollbar {
    width: 0px;
}
.myLayout{
    height: 100vh;
}
  