.withdrawProveBox2{
    width: 100%;
    display: flex;
    justify-content: center;
}
.withdrawProve2 {
    width: 620px;
    padding: 20px 40px 60px;
    background-color: #fff;
    background-position: -300px -300px;
    background-repeat: no-repeat;
    background-size: 160px 160px;
    font-size: 18px;
}

.withdrawProve2 p:first-child {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.withdrawProve2 p:nth-of-type(2) {
    text-align: justify;
    text-indent: 2em;
    margin-bottom: 20px;
}

.withdrawProve2 p:nth-of-type(3) {
    text-align: justify;
    text-indent: 2em;
    margin-bottom: 20px;
}

.withdrawProve2 p:nth-of-type(4) {
    text-align: justify;
    text-indent: 2em;
    margin-bottom: 20px;
}

/* .withdrawProve2 p:nth-of-type(5) {
    text-align: justify;
    text-indent: 2em;
    margin-bottom: 20px;
} */

.withdrawProve2 p:nth-of-type(5) {
    margin-left: 288px;
    margin-bottom: 10px;
}
.withdrawProve2 p:nth-of-type(6) {
    margin-left: 288px;
    margin-bottom: 10px;
}

.withdrawProve2 table {
    width: 100%;
}

.withdrawProve2 table th {
    width: 50%;
    text-align: center;
    font-weight: 400;
}


